.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #588ebb;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.award-section {
  text-align: center;
  padding: 20px;
  background-color: #588ebb; /* Replace with your desired background color */
  color: #fff; /* Replace with your desired text color */
}

.award-section-page {
  text-align: center;
  padding: 20px;
  background-color: #1e1e1e; /* Replace with your desired background color */
  color: #fff; /* Replace with your desired text color */
}

.award-section h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.award-section p {
  font-size: 16px;
  margin-bottom: 30px;
}

.award-badges {
  display: flex;
  justify-content: center;
  gap: 20px; /* Adjust the gap as needed */
}

.award-badges img {
  height: 100px; /* Adjust based on your images' aspect ratio */
  width: auto;
}
